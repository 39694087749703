import React, {
  useState,
  type HTMLAttributes,
  type MouseEvent,
  type ReactElement,
} from "react";
import { IconFa, Box, crukTheme, PopOver } from "@cruk/cruk-react-components";
import { faEnvelope, faComment } from "@fortawesome/free-solid-svg-icons";
import { useSWRConfig } from "swr";
import {
  faFacebookF,
  faFacebookMessenger,
  faWhatsapp,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { useTracking } from "@fwa/src/hooks/useTracking";
import { fetcher } from "@fwa/src/services/apiClient";
import { getFwsUrlForPage } from "@fwa/src/utils/pageUtils";
import {
  isMobile,
  isChrome,
  hasShareApi,
  isBrowser,
} from "@fwa/src/utils/browserUtils";
import {
  getShareContentFromEntity,
  getShareURLsFromEntity,
} from "@fwa/src/utils/shareUtils";
import {
  fwsUrlPage,
  fwsUrlPageShareCount,
} from "@fwa/src/services/pageService";
import { useLayoutEffectBrowser } from "@fwa/src/hooks/useLayoutEffectBrowser";

import { CopyToClipBoard } from "@fwa/src/components/CopyToClipboard";

import { SocialLink, ShareIcons } from "@fwa/src/components/Share/styles";
import { Column } from "@fwa/src/components/styles";

import {
  type FeedItemType,
  type PageType,
  type ShareContentType,
  type ShareURLsObjectType,
} from "@fwa/src/types";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";

type PopOverPositionType =
  | "top"
  | "topLeft"
  | "left"
  | "right"
  | "bottom"
  | "bottomLeft";

type ShareProps = {
  page: PageType;
  feedItem: FeedItemType | null;
  isOwner: boolean;
  popoverPosition?: PopOverPositionType;
  ctaLocation: string;
  children: ReactElement<HTMLAttributes<HTMLElement>>;
  full?: boolean;
};

type FwsShareRes = { sharesCount: number };

export const Share = ({
  children,
  isOwner,
  page,
  feedItem,
  popoverPosition = "bottomLeft",
  ctaLocation = "",
  full = false,
}: ShareProps) => {
  const { trackEventOptimizely } = useOptimizelyContext();
  const entityType = feedItem?.entityType || page?.entityType || "";
  const { trackError, trackEventGtm } = useTracking();
  const [showMobile, setShowMobile] = useState(false);
  const [showShareApi, setShowShareApi] = useState(false);
  const [nativeShareIsOk, setNativeShareIsOk] = useState(true);
  const [shareUrlsObject, setShareUrlsObject] = useState<ShareURLsObjectType>({
    facebook: ``,
    twitter: ``,
    linkedIn: ``,
    whatsapp: ``,
    sms: ``,
    email: ``,
    fbmessenger: ``,
    copy: ``,
  });

  const { mutate: globalMutate } = useSWRConfig();
  const [shareContentObject, setShareContentObject] =
    useState<ShareContentType>({
      title: ``,
      description: ``,
      image: ``,
      emailUri: ``,
      entityUrl: ``,
    });

  const bumpPageShareCount = async () => {
    // This only works for FRP at the minute.
    if (page.entityType !== "FundraisingPage") return;
    // if (!feedItem?.page) return;
    const pageId = feedItem?.page.uniqueId || page.uniqueId;
    if (!isOwner) {
      // logged out user bumps generic share count
      const fwsShareCountUrl = fwsUrlPageShareCount(
        feedItem?.page.entityType || page.entityType,
        pageId,
      );
      const fwsShareCount = await fetcher(fwsShareCountUrl).catch((error) => {
        trackError(error as Error, {
          function: "fwsSharesCount",
        });
      });
      if (!fwsShareCount) {
        trackError(new Error("no res from fwsShareCountUrl"), {
          function: "fwsSharesCount",
        });
        return;
      }
      const fwsShareCountObject = fwsShareCount as FwsShareRes;
      const newPageData = {
        ...(feedItem?.page || page),
        ...fwsShareCountObject,
      };
      const pageToMutate = feedItem?.page || page;
      const pageKey = getFwsUrlForPage(pageToMutate);
      globalMutate(pageKey, newPageData, false).catch(() => {
        trackError(new Error("Could not update sharesCount locally"), {
          function: "mutatePage in bumpShareCount",
        });
      });
    } else {
      // logged in user bumps owner share count
      const slug = getFwsUrlForPage(feedItem?.page || page);
      const url = fwsUrlPage({ pageId });
      const ownerShareCount =
        (feedItem?.page.ownerShareCount || page.ownerShareCount || 0) + 1;
      const updatedPageData = await fetcher(url, {
        method: "PATCH",
        body: JSON.stringify({ ownerShareCount }),
      })
        .then((pageData) => pageData as PageType)
        .catch((error) => {
          trackError(error as Error, {
            function: "bumpOwnerSharesCount",
          });
        });

      if (!updatedPageData) {
        trackError(
          new Error("no updated page returned from bumpOwnerSharesCount"),
          {
            function: "fwsSharesCount",
          },
        );
        return;
      }
      globalMutate(slug, updatedPageData, false).catch(() => {
        trackError(new Error("Could not update ownerSharesCount locally"), {
          function: "mutatePage in bumpOwnerSharesCount",
        });
      });
    }
  };

  // tracking for cta click event happens before a confirmed share event
  const sendCtaTrackingEvent = () => {
    trackEventGtm({
      event: "share_cta",
      cta: ctaLocation,
      entityType,
      isOwner,
    });
    if (ctaLocation === "feedItem_shareprompt_modal") {
      trackEventOptimizely({
        eventKey: "of_share_new_post",
      });
    }
    trackEventOptimizely({
      eventKey: "of_share",
    });
  };

  // tracking for actual share
  const socialShareClick = (
    e: MouseEvent<HTMLElement>,
    key: string,
    url: string,
  ) => {
    e.preventDefault();

    // do tracking

    // The mobile chrome browser does not allow js to open apps so
    // we are removing the event handler in this case.
    if (isChrome && isMobile) {
      // A hail mary in case prevent default on a link wont work with certain mobile user agents
      bumpPageShareCount().catch((err) => {
        trackError(err as Error, {
          function: "bumpShareCount",
        });
      });
      trackEventGtm({
        event: "share",
        action: key,
        entityType,
        isOwner,
      });
      return;
    }
    bumpPageShareCount().catch((err) => {
      trackError(err as Error, {
        function: "bumpShareCount",
      });
    });
    trackEventGtm({
      event: "share",
      action: key,
      entityType,
      isOwner,
    });

    // execute social share
    window.open(
      url,
      `${key}${Math.random()}}`,
      "toolbar=0,status=0,width=548,height=400",
    );
  };

  const copyForShareClick = () => {
    bumpPageShareCount().catch((err) => {
      trackError(err as Error, {
        function: "bumpShareCount",
      });
    });
    trackEventGtm({
      event: "share",
      action: "copy url",
      entityType,
      isOwner,
    });
  };

  const nativeShare = () => {
    if (!isBrowser) return;
    // track share
    sendCtaTrackingEvent();
    bumpPageShareCount().catch((err) => {
      trackError(err as Error, {
        function: "bumpPageShareCount",
      });
    });
    // execute native share
    const navigatorShareObject = {
      title: shareContentObject.title,
      text: shareContentObject.description,
      url: shareContentObject.entityUrl,
    };
    window.navigator.share(navigatorShareObject).catch((err: Error) => {
      trackError(err, { function: "nativeShare" });
      setNativeShareIsOk(false);
    });
  };

  // this figures out what to share from url in browser, so we need window to exist
  useLayoutEffectBrowser(() => {
    const newShareUrlsObject = getShareURLsFromEntity(feedItem || page);
    const newShareUrlsIOSFriendly = {
      ...newShareUrlsObject,
      sms: newShareUrlsObject.sms.replace("?", "?&"),
    };
    const newShareDataObject = getShareContentFromEntity(feedItem || page);
    setShareContentObject(newShareDataObject);
    setShareUrlsObject(newShareUrlsIOSFriendly);

    if (showMobile !== isMobile) {
      setShowMobile(isMobile);
    }
    if (showShareApi !== hasShareApi) {
      setShowShareApi(hasShareApi);
    }
  }, []);

  const content = () => (
    <Column>
      <Box marginBottom="xxs" padding="xxs">
        <ShareIcons>
          <SocialLink
            href={shareUrlsObject.facebook}
            aria-label="share on facebook"
            onClick={(e: MouseEvent<HTMLAnchorElement>) =>
              socialShareClick(e, "facebook", shareUrlsObject.facebook)
            }
            data-cta-type="share-facebook"
            $backgroundColor="#4267b2"
          >
            <IconFa faIcon={faFacebookF} color="textLight" size="2rem" />
          </SocialLink>

          <SocialLink
            href={shareUrlsObject.twitter}
            aria-label="share on twitter"
            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
              socialShareClick(e, "twitter", shareUrlsObject.twitter);
            }}
            data-cta-type="share-twitter"
            $backgroundColor="#000000"
          >
            <IconFa faIcon={faXTwitter} color="textLight" size="2rem" />
          </SocialLink>

          {showMobile ? (
            <SocialLink
              href={shareUrlsObject.whatsapp}
              aria-label="share on whatsapp"
              onClick={(e: MouseEvent<HTMLAnchorElement>) =>
                socialShareClick(e, "whatsapp", shareUrlsObject.whatsapp)
              }
              data-cta-type="share-whatsapp"
              $backgroundColor="#25D366"
            >
              <IconFa faIcon={faWhatsapp} color="textLight" size="2rem" />
            </SocialLink>
          ) : null}

          {showMobile ? (
            <SocialLink
              href={shareUrlsObject.fbmessenger}
              aria-label="share on facebook messenger"
              onClick={(e: MouseEvent<HTMLAnchorElement>) =>
                socialShareClick(e, "fbmessenger", shareUrlsObject.fbmessenger)
              }
              data-cta-type="share-fbmessenger"
              $backgroundColor="#0078FF"
            >
              <IconFa
                faIcon={faFacebookMessenger}
                color="textLight"
                size="2rem"
              />
            </SocialLink>
          ) : null}

          {showMobile ? (
            <SocialLink
              href={shareUrlsObject.sms}
              aria-label="share by sms"
              onClick={(e: MouseEvent<HTMLAnchorElement>) =>
                socialShareClick(e, "sms", shareUrlsObject.sms)
              }
              data-cta-type="share-sms"
              $backgroundColor="#53d769"
            >
              <IconFa faIcon={faComment} color="textLight" size="2rem" />
            </SocialLink>
          ) : null}

          <SocialLink
            href={shareUrlsObject.linkedIn}
            aria-label="share on linkedIn"
            onClick={(e: MouseEvent<HTMLAnchorElement>) =>
              socialShareClick(e, "linkedIn", shareUrlsObject.linkedIn)
            }
            data-cta-type="share-linkedIn"
            $backgroundColor="#0077b5"
          >
            <IconFa faIcon={faLinkedinIn} color="textLight" size="2rem" />
          </SocialLink>

          <SocialLink
            href={shareUrlsObject.email}
            aria-label="share with email"
            onClick={(e: MouseEvent<HTMLAnchorElement>) =>
              socialShareClick(e, "email", shareUrlsObject.email)
            }
            data-cta-type="share-email"
            $backgroundColor={crukTheme.colors.primary}
          >
            <IconFa faIcon={faEnvelope} color="textLight" size="2rem" />
          </SocialLink>
        </ShareIcons>
        <CopyToClipBoard
          shareCopy={shareUrlsObject.copy}
          onClick={copyForShareClick}
          data-cta-type="share-copy-url"
        />
      </Box>
    </Column>
  );

  return (
    <div data-component={`share-${ctaLocation}`}>
      {nativeShareIsOk && hasShareApi && isMobile ? (
        <>
          {React.cloneElement(children, {
            onClick: nativeShare,
          })}
        </>
      ) : (
        <PopOver
          full={full}
          modalContent={content()}
          modalLabel="share options"
          position={popoverPosition}
          onPopOverIsOpenChange={(isOpen: boolean) => {
            if (isOpen) {
              sendCtaTrackingEvent();
            }
          }}
        >
          {children}
        </PopOver>
      )}
    </div>
  );
};

export default Share;
